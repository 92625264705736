







import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

import WidgetNameDisplay from '~/components/common/widget/displays/WidgetNameDisplay.vue'
@Component({
  components: {
    WidgetNameDisplay,
  },
  name: 'dashboard-place-holder-widget',
})
export default class PlaceHolderWidget extends Vue {
  @Prop() public widgetName: string
}
