








import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import AssigneeManagementPlaceholder from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/Placeholder.vue'
import CalendarViewPlaceholder from '~/components/dashboard/widget/collaborate/calendarViewWidget/Placeholder.vue'
import DailyTaskListPlaceholder from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/Placeholder.vue'
import TopicListPlaceholder from '~/components/dashboard/widget/collaborate/topicListWidget/Placeholder.vue'

@Component({
  components: {
    CalendarViewPlaceholder,
    AssigneeManagementPlaceholder,
    DailyTaskListPlaceholder,
    TopicListPlaceholder,
  },
  name: 'dashboard-place-holder-container',
})
export default class PlaceHolderContainer extends Vue {}
