








import { Component } from 'vue-property-decorator'
import Vue from 'vue'

import AddWidgetDial from '~/components/dashboard/AddWidgetDial.vue'

@Component({
  components: {
    AddWidgetDial,
  },
  name: 'dashboard-without-widget',
})
export default class WithoutWidget extends Vue {}
