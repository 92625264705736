






















import { Component, Inject } from 'vue-property-decorator'
import Vue from 'vue'

import { WidgetConfigEntity } from '~/apollo/types'
import CollabAssigneeManagementWidget from '~/components/dashboard/widget/collaborate/assigneeManagementWidget/AssigneeManagementWidget.vue'
import CollabCalendarViewWidget from '~/components/dashboard/widget/collaborate/calendarViewWidget/CalendarViewWidget.vue'
import CollabDailyTaskListWidget from '~/components/dashboard/widget/collaborate/dailyTaskListWidget/DailyTaskListWidget.vue'
import CollabTopicListWidget from '~/components/dashboard/widget/collaborate/topicListWidget/TopicListWidget.vue'
import PlaceHolderWidget from '~/components/dashboard/PlaceHolderWidget.vue'

@Component({
  components: {
    CollabDailyTaskListWidget,
    CollabAssigneeManagementWidget,
    CollabCalendarViewWidget,
    CollabTopicListWidget,
    PlaceHolderWidget,
  },
  name: 'dashboard-widget-container',
})
export default class WidgetContainer extends Vue {
  @Inject()
  public containerClass!: string
  @Inject()
  public dragClass!: string

  public dataLoading = false
  public widgetVisibilityMap: KeyMap<WidgetConfigEntity> = {}

  get sortedWidgetData() {
    return this.$store.myGetters.getWidgetConfigs.sort((wcA, wcB) => {
      if (wcA.order === wcB.order) {
        return 0
      }
      return (wcA.order || 0) > (wcB.order || 0) ? 1 : -1
    })
  }

  public visibilityChanged(
    isVisible: Boolean,
    entry: IntersectionObserverEntry,
    widgetConfig: WidgetConfigEntity,
  ) {
    // Will not set it back to invisible to retain the state
    if (!this.widgetVisibilityMap[widgetConfig.id]) {
      Vue.set(this.widgetVisibilityMap, widgetConfig.id, isVisible)
    }
  }

  public mounted() {
    this.$emit('completed')
  }
}
